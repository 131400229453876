.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer{
  cursor: pointer;
}

.drive_list_link{
  float: right;
  text-decoration: none;
}

.text-decoration{
  text-decoration: none;
}


.border-bottom-width{
  border-bottom-width:0px!important;
}

.width-100{
  width: 100%;
}

.carousel-inner{
  border-radius: 12px;
}

.card_view{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 480px; */
}

.container_box{
  background-color: #f2f2f2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.navbar{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay color */
  color: #fff; /* Text color for the overlay */
  padding: 20px;
  box-sizing: border-box;
}

.position_relative{
  position: relative;
}

.height_140{
  height: 140px;
}

.margin-left-20{  
  margin-left: 20px;
}

.text-color{
  color: #000;
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-link:hover {
  background-color: #0056b3;
  color: #fff;
}

.button-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
.mt-10{
  margin-top: 10px;
}

.card_view_home{  
  position: absolute;
  top: 30%;
  left: 20px;
  right: 20px;
}

.ml-20{
  margin-left: 20px;
}

.background-video{
  position: relative;
}

.container_box_home{
  /* height: 100vh; */
}

.background-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); Adjust the opacity as needed */
  z-index: 1;
}

.border-radius-12{
  border-radius: 12px;
}

.border-radius-12-12{
  border-radius: 0px 0px 12px 12px
}

.padding-bottom-10{
  padding-bottom: 10px;
}

.map{
  width: 50%;
  float: right;
}

.float-right{
  float: right;
}

.card_view_home .card {
  /* height: 320px; */
 }
 
 .footer-boxes .img-fluid{
    /* height: 100%;   */
 }
 .footer-boxes .card{
 border: none;
 background: no-repeat;
 background-color: #000;
 border-radius: 12px;
 }

 .footer-boxes .card.slide2{
  border: none;
  background: no-repeat; 
  }

 
 
 .disabled-color{
  color: gray;
 }

@media (max-width: 767px) {
 .map{
  width: 100%;
  float: none;
 }
 .card_view_home{
  top:0px;
 }

 .background-video{
  height: 130vh;
 }

 .card_view_home .card {
  height: auto;
 }

}

@media (max-width: 457px) { 
 
  .background-video{
   height: 130vh;
  }
 }

